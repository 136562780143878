import React, { createContext, useReducer } from 'react';
import CollectorProfileReducer from "./collector-profile.reducer";
import {ICollectorProfileState} from "./collector-profile.interfaces";

const initialState: ICollectorProfileState = {
    data: {
       userId: 0,
       isActive: false,
       type: '',
        registerPurpose: [],
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        organization: '',
        website: '',
        address: '',
        title: '',
        description: '',
        paymentFreePass: false,
        currency: {
            id: -1,
            code: '',
            name: '',
            namePlural: '',
            symbol: '',
            symbolNative: '',
        },
        country: {id: -1, name: ''},
        state: {id: -1, name: ''},
        city: {id: -1, name: ''},
        photoUrl: '',
        totalReviewedObjects: 0,
        totalObjects: 0,
    },
    activeFeedbackRequests: [],
    artifacts: [],
    artifactsPagination: {
        page: 0,
        size: 21,
        totalCount: 0,
        numberOfPages: 0,
    },
    isLoading: true,
    setData: () => null,
    setArtifacts: () => null,
    setLoading: () => null,
    setCurrentPage: () => null,
}

export const CollectorProfileContext = createContext(initialState);

export const CollectorProfileProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(CollectorProfileReducer, initialState);

    const setData = (data: unknown): void => {
        dispatch({
            type: 'SET_DATA',
            payload: data
        })
    }

    const setArtifacts = (data: unknown): void => {
        dispatch({
            type: 'SET_ARTIFACTS',
            payload: data,
        })
    }

    const setCurrentPage = (page: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: page,
        })
    }

    const setLoading = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING',
            payload: loading
        });
    }

    return (
        <CollectorProfileContext.Provider value={{
            ...state,
            setData,
            setArtifacts,
            setLoading,
            setCurrentPage,
        }}>
            {children}
        </CollectorProfileContext.Provider>
    )
}
