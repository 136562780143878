import React, { createContext, useReducer } from 'react';
import CollectorArtifactReducer from './collector-artifact.reducer';
import { ICollectorArtifactState } from './collector-artifact.interfaces';

const initialState: ICollectorArtifactState = {
    artifact: {
        id: 0,
        title: '',
        description: '',
        exactValue: null,
        minimumValue: null,
        maximumValue: null,
        width: null,
        height: null,
        depth: null,
        weight: null,
        exactProductionYear: null,
        lowerProductionYear: null,
        upperProductionYear: null,
        isFeedbackImported: false,
        isSharedToPublicFeed: false,
        paymentFreePass: false,
        origin: '',
        material: '',
        style: '',
        artist: '',
        artistBio: '',
        condition: '',
        createdDate: '',
        documents: [],
        feedbackRequests: [],
        experts: [],
        photos: [],
        collections: [],
        categories: [],
        materials: [],
        conditions: [],
        eras: [],
        origins: [],
        styles: [],
        feedbacks: [],
        artistImageUrl: null,
    },
    isLoading: true,
    setArtifact: () => null,
    setLoadingState: () => null,
};

export const CollectorArtifactContext = createContext(initialState);

export const CollectorArtifactProvider: any = ({ children }: any) => {
    const [state, dispatch] = useReducer(CollectorArtifactReducer, initialState);

    const setArtifact = (artifact: unknown): void => {
        dispatch({
            type: 'SET_ARTIFACT',
            payload: { artifact },
        });
    };

    const setLoadingState = (isLoading: boolean): void => {
        dispatch({
            type: 'SET_LOADING_STATE',
            payload: isLoading,
        });
    };

    return (
        <CollectorArtifactContext.Provider
            value={{
                ...state,
                setArtifact,
                setLoadingState,
            }}
        >
            {children}
        </CollectorArtifactContext.Provider>
    );
};
