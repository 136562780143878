import React, { createContext, useReducer } from 'react';
import ArchivistProfileReducer from "./archivist-profile.reducer";
import {IArchivistProfileState} from "./archivist-profile.interfaces";

const initialState: IArchivistProfileState = {
    data: {
        userId: 0,
        isActive: false,
        type: '',
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        organization: '',
        website: '',
        address: '',
        title: '',
        description: '',
        reference: '',
        totalJobs: 0,
        totalActiveJobs: 0,
        currency: {
            id: -1,
            code: '',
            name: '',
            namePlural: '',
            symbol: '',
            symbolNative: '',
        },
        country: {id: -1, name: ''},
        state: {id: -1, name: ''},
        city: {id: -1, name: ''},
        photoUrl: '',
    },
    setData: () => null,
}

export const ArchivistProfileContext = createContext(initialState);

export const ArchivistProfileProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(ArchivistProfileReducer, initialState);

    const setData = (data: unknown): void => {
        dispatch({
            type: 'SET_DATA',
            payload: data
        })
    }

    return (
        <ArchivistProfileContext.Provider value={{
            ...state,
            setData,
        }}>
            {children}
        </ArchivistProfileContext.Provider>
    )
}
