import React, { createContext, useReducer } from 'react';
import GlobalReducer from './global.reducer';
import {IGlobalState} from "./global.interfaces";

const initialState: IGlobalState|undefined = {
    uploadPhotosBatch1: false,
    uploadPhotosBatch2: false,
    uploadPhotosBatch3: false,
    shouldReloadBatch1: false,
    shouldReloadBatch2: false,
    shouldReloadBatch3: false,
    jobId: null,
    setUploadPhotosBatch1: () => null,
    setUploadPhotosBatch2: () => null,
    setUploadPhotosBatch3: () => null,
    setShouldReloadBatch1: () => null,
    setShouldReloadBatch2: () => null,
    setShouldReloadBatch3: () => null,
    manageUploadPhotosBatch: () => null,
    setJobId: () => null,
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(GlobalReducer, initialState);

    const setUploadPhotosBatch1 = (value: boolean): void => {
        dispatch({
            type: 'SET_UPLOAD_PHOTOS_BATCH_1',
            payload: value,
        })
    }

    const setUploadPhotosBatch2 = (value: boolean): void => {
        dispatch({
            type: 'SET_UPLOAD_PHOTOS_BATCH_2',
            payload: value,
        })
    }

    const setUploadPhotosBatch3 = (value: boolean): void => {
        dispatch({
            type: 'SET_UPLOAD_PHOTOS_BATCH_3',
            payload: value,
        })
    }

    const setShouldReloadBatch1 = (value: boolean): void => {
        dispatch({
            type: 'SET_SHOULD_RELOAD_BATCH_1',
            payload: value,
        })
    }

    const setShouldReloadBatch2 = (value: boolean): void => {
        dispatch({
            type: 'SET_SHOULD_RELOAD_BATCH_2',
            payload: value,
        })
    }

    const setShouldReloadBatch3 = (value: boolean): void => {
        dispatch({
            type: 'SET_SHOULD_RELOAD_BATCH_3',
            payload: value,
        })
    }

    const manageUploadPhotosBatch = (chunks: any): void => {
        dispatch({
            type: 'MANAGE_UPLOAD_PHOTOS_BATCH',
            payload: chunks,
        })
    }

    const setJobId = (jobId: number): void => {
        dispatch({
            type: 'SET_JOB_ID',
            payload: jobId,
        })
    }

    return (
        <GlobalContext.Provider value={{
            ...state,
            setUploadPhotosBatch1,
            setUploadPhotosBatch2,
            setUploadPhotosBatch3,
            setShouldReloadBatch1,
            setShouldReloadBatch2,
            setShouldReloadBatch3,
            manageUploadPhotosBatch,
            setJobId,
        }}>
            {children}
        </GlobalContext.Provider>
    )
}
