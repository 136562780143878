import React, { createContext, useReducer } from 'react';
import ExpertListingReducer from "./expert-listing.reducer";
import {IExpertListingState} from "./expert-listing.interfaces";


const initialState: IExpertListingState = {
    experts: [],
    isLoading: true,
    displayEmptyPage: false,
    statistics: {
        totalUsers: 0
    },
    pagination: {
        page: 1,
        size: 20,
        totalCount: 0,
        numberOfPages: 0,
    },
    setExperts: () => null,
    setCurrentPage: () => null,
    setLoading: () => null,
}

export const ExpertListingContext = createContext(initialState);

export const ExpertListingProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(ExpertListingReducer, initialState);

    const setExperts = (data: unknown): void => {
        dispatch({
            type: 'SET_EXPERTS',
            payload: data
        })
    }

    const setCurrentPage = (page: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: page,
        })
    }

    const setLoading = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING',
            payload: loading
        });
    }

    return (
        <ExpertListingContext.Provider value={{
            ...state,
            setExperts,
            setCurrentPage,
            setLoading,
        }}>
            {children}
        </ExpertListingContext.Provider>
    )
}
