import React, { createContext, useReducer } from 'react';
import FeedbackRequestsReducer from "./feedback.reducer";
import {IFeedbackRequestState} from "./feedback.interfaces";

const initialState: IFeedbackRequestState = {
    data: {
        id: -1,
        dateCreated: '',
        isCompleted: false,
        message: '',
        morePhotosRequired: false,
        artifact: null,
        collector: {
            userId: -1,
            firstName: '',
            lastName: '',
            email: '',
            photoUrl: null,
        },
        feedback: null,
        comments: [],
        feedbackDraft: null,
    },
    isLoading: true,
    setData: () => null,
    setLoading: () => null,
}

export const FeedbackRequestContext = createContext(initialState);

export const FeedbackRequestProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(FeedbackRequestsReducer, initialState);

    const setData = (data: unknown, id: number): void => {
        dispatch({
            type: 'SET_DATA',
            payload: {data, id}
        })
    }

    const setLoading = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING',
            payload: loading
        });
    }

    return (
        <FeedbackRequestContext.Provider value={{
            ...state,
            setData,
            setLoading,
        }}>
            {children}
        </FeedbackRequestContext.Provider>
    )
}
