import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {Box, CssBaseline} from "@material-ui/core";

import {AppRoutes, getJobIdFromStorage, getToken} from "./core/helpers";
import {SignIn} from "./scenes/auth/scenes/SignIn";
import {CreateAccount} from "./scenes/auth/scenes/CreateAccount";

import {ConsumerToolbar} from "./components/layout/ConsumerToolbar";
import {Loader} from "./components/_others";
import {DashboardApp} from "./scenes/dashboard";
import {CollectorApp} from "./scenes/consumer/collector-app";

import {PrivateRoute} from "./components/_others/PrivateRoute";
import {ObjectDetailsProvider} from "./core/context/consumer/objectDetails";
import {SharedWithMeProvider} from "./core/context/consumer/sharedWithMe";
import {SharedObjectProvider} from "./core/context/consumer/sharedObject";
import {CollectionProvider} from "./core/context/consumer/collection";

import {UserContext} from "./core/context/user";
import {UserApi} from "./core/api/user";
import {UserRoles} from "./core/enums";
import {homepageByUserRole} from "./core/helpers/redirect-helper";
import {lightTheme} from "./assets/themes/light-theme";
import {darkTheme} from "./assets/themes/dark-theme";
import {ThemeProvider} from "@material-ui/core/styles";
import {PrivacyPolicy} from "./scenes/shared/PrivacyPolicy";
import {TermsAndConditions} from "./scenes/shared/TermsAndConditions";
import {OurMission} from "./scenes/shared/OurMission";
import {HelperApp} from "./scenes/consumer/helper-app";
import {HelperToolbar} from "./components/layout/HelperToolbar";
import {Tutorial} from "./scenes/consumer/Tutorial";
import {UtilsContext} from "./core/context/utils.context";

export const AppRouting: React.FC = () => {

    const [loading, setLoading] = useState(true);

    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);

    useEffect(() => {
        const token = getToken();

        if(!token) {
            setLoading(false);
            return;
        }

        UserApi.getUser()
            .then((res: any) => {
                userContext.setUser(res.data);
                setLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response)
                setLoading(false);
                userContext.onLogout();
                window.localStorage.clear();
                window.location.replace("/");
            })

        if(getJobIdFromStorage() !== null) {
            utilsContext.selectJob(Number(getJobIdFromStorage()));
        }
    }, [])

    return (
        <ThemeProvider theme={userContext.lightTheme ? lightTheme : darkTheme}>
            <CssBaseline />
        <Router>
            {
                ((!userContext.user && !loading) ||
                    userContext.user?.role === UserRoles.COLLECTOR ||
                    userContext.user?.role === UserRoles.ARCHIVIST ||
                    userContext.user?.role === UserRoles.CURATOR
                ) &&
                    <ConsumerToolbar />
            }
            {
                (userContext.user?.role === UserRoles.ARCHIVIST || userContext.user?.role === UserRoles.CURATOR) &&
                utilsContext.selectedJob !== -1 &&
                   <HelperToolbar />
            }
            {
                !loading &&
                <Box>
                    <Switch>

                        <Route exact path="/"><Redirect to={homepageByUserRole(userContext.user?.role)} /></Route>
                        <Route path={AppRoutes.SIGN_IN} component={SignIn} />
                        <Route path={AppRoutes.CREATE_ACCOUNT} component={CreateAccount} />
                        <Route path={AppRoutes.PRIVACY_POLICY} component={PrivacyPolicy} />
                        <Route path={AppRoutes.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
                        <Route path={AppRoutes.OUR_MISSION} component={OurMission} />
                        <Route path={AppRoutes.TUTORIAL} component={Tutorial} />

                        <Route
                            path={'/collector'}
                            render={() => (
                                <>
                                        <SharedWithMeProvider>
                                            <CollectionProvider>
                                                <ObjectDetailsProvider>
                                                    <SharedObjectProvider>
                                                        <PrivateRoute
                                                            path={'/collector'}
                                                            component={CollectorApp}
                                                            restrictedRoles={[UserRoles.COLLECTOR, UserRoles.ARCHIVIST, UserRoles.CURATOR]}
                                                        />
                                                    </SharedObjectProvider>
                                                </ObjectDetailsProvider>
                                            </CollectionProvider>
                                        </SharedWithMeProvider>
                                </>
                            )}
                        >
                        </Route>

                        <Route
                            path={'/helper'}
                            component={HelperApp}
                            restrictedRoles={[UserRoles.ARCHIVIST, UserRoles.CURATOR]}
                        />

                        <Route
                            path={'/dashboard'}
                            render={() => (
                                <DashboardApp />
                            )}
                        />

                        <Route exact path="*"><Redirect to={AppRoutes.MY_OBJECTS} /></Route>
                    </Switch>
                </Box>
            }
            {
                loading && <Loader />
            }
        </Router>
        </ThemeProvider>
    )
}
