import React, { createContext, useReducer } from 'react';
import {IAction} from "./interfaces";

const initialState: any = {
    snackbar: {
        severity: '',
        text: '',
    },
    appLoader: false,

    selectedJob: -1,
}

const Reducer = (state: any, action: IAction): any => {
    switch(action.type) {
        case 'ADD':
            return {
                ...state,
                snackbar: {
                    severity: action.payload.severity,
                    text: action.payload.text || 'Sorry, there was an error. Please try again later.',
                }
            }
        case 'CLOSE':
            return {
                ...state,
                snackbar: {
                    severity: '',
                    text: '',
                }
            }
        case 'SET_LOADER': {
            return {
                ...state,
                appLoader: action.payload
            }
        }
        case 'SELECT_JOB': {
            return {
                ...state,
                selectedJob: action.payload
            }
        }
        default:
            return state;
    }
}

export const UtilsContext = createContext(initialState);

export const UtilsProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(Reducer, initialState);

    const addSnackbar = (severity: string, text?: string): void => {
        dispatch({
            type: 'ADD',
            payload: {severity, text}
        })
    }

    const closeSnackbar = (): void => {
        dispatch({
            type: 'CLOSE',
            payload: null
        })
    }

    const setAppLoaderState = (value: boolean): void => {
        dispatch({
            type: 'SET_LOADER',
            payload: value,
        })
    }

    const selectJob = (id: number): void => {
        dispatch({
            type: 'SELECT_JOB',
            payload: id,
        })
    }

    return (
        <UtilsContext.Provider value={{
            ...state,
            addSnackbar,
            closeSnackbar,
            setAppLoaderState,
            selectJob,
        }}>
            {children}
        </UtilsContext.Provider>
    )
}
