import React, { createContext, useReducer } from 'react';
import ArchivistListingReducer from "./archivist-listing.reducer";
import {IArchivistListingState} from "./archivist-listing.interfaces";

const initialState: IArchivistListingState = {
    archivists: [],
    isLoading: true,
    displayEmptyPage: false,
    statistics: {
        totalUsers: 0
    },
    pagination: {
        page: 1,
        size: 20,
        totalCount: 0,
        numberOfPages: 0,
    },
    setArchivists: () => null,
    setCurrentPage: () => null,
    setLoading: () => null,
}

export const ArchivistListingContext = createContext(initialState);

export const ArchivistListingProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(ArchivistListingReducer, initialState);

    const setArchivists = (data: unknown): void => {
        dispatch({
            type: 'SET_ARCHIVISTS',
            payload: data
        })
    }

    const setCurrentPage = (page: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: page,
        })
    }

    const setLoading = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING',
            payload: loading
        });
    }

    return (
        <ArchivistListingContext.Provider value={{
            ...state,
            setArchivists,
            setCurrentPage,
            setLoading,
        }}>
            {children}
        </ArchivistListingContext.Provider>
    )
}
