import React, { createContext, useReducer } from 'react';
import CollectorListingReducer from "./collector-listing.reducer";
import {ICollectorListingState} from "./collector-listing.interfaces";

const initialState: ICollectorListingState = {
    collectors: [],
    isLoading: true,
    displayEmptyPage: false,
    statistics: {
        totalUsers: 0
    },
    pagination: {
        page: 1,
        size: 20,
        totalCount: 0,
        numberOfPages: 0,
    },
    setCollectors: () => null,
    setCurrentPage: () => null,
    setLoading: () => null,
}

export const CollectorListingContext = createContext(initialState);

export const CollectorListingProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(CollectorListingReducer, initialState);

    const setCollectors = (data: unknown): void => {
        dispatch({
            type: 'SET_COLLECTORS',
            payload: data
        })
    }

    const setCurrentPage = (page: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: page,
        })
    }

    const setLoading = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING',
            payload: loading
        });
    }

    return (
        <CollectorListingContext.Provider value={{
            ...state,
            setCollectors,
            setCurrentPage,
            setLoading,
        }}>
            {children}
        </CollectorListingContext.Provider>
    )
}
