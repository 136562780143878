import {ICollectionState} from "./collection.interfaces";
import React, {createContext, useReducer} from "react";
import CollectionReducer from "./collection.reducer";
import {SortObjectsOptions} from "../../../enums";

const initialState: ICollectionState = {
    id: 0,
    name: '',
    isLoading: true,
    displayEmptyPage: false,
    artifacts: [],
    pagination: {
        page: 0,
        size: 20,
        totalCount: 0,
        numberOfPages: 0,
        sortBy: SortObjectsOptions.DateCreated,
        asc: false,
    },
    statistics: {
        totalObjects: 0,
        totalValue: 0,
    },
    modals: {
        displayAddObjectModal: false,
        displayDeleteCollectionModal: false,
        displayDeleteObjectModal: -1,
        displayEditCollectionNameModal: false,
        toggleAddObjectModal: () => null,
        toggleDeleteCollectionModal: () => null,
        toggleDeleteObjectModal: () => null,
        toggleEditCollectionNameModal: () => null,
    },
    setArtifacts: () => null,
    setLoading: () => null,
    setCurrentPage: () => null,
    setCollectionId: () => null,
    setSortBy: () => null,
}

export const CollectionContext = createContext(initialState);

export const CollectionProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(CollectionReducer, initialState);

    const setArtifacts = (artifacts: any): void => {
        dispatch({
            type: 'SET_ARTIFACTS',
            payload: artifacts,
        })
    }

    const setCurrentPage = (page: number, size: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: {page, size},
        })
    }

    const setSortBy = (sortBy: string, asc: boolean): void => {
        dispatch({
            type: 'SET_SORT_OPTION',
            payload: {sortBy, asc},
        })
    }

    const setLoading = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING',
            payload: loading
        })
    }

    const setCollectionId = (id: number): void => {
        dispatch({
            type: 'SET_COLLECTION_ID',
            payload: id,
        })
    }

    const toggleDeleteCollectionModal = (): void => {
        dispatch({
            type: 'TOGGLE_DELETE_COLLECTION_MODAL',
            payload: !state.modals.displayDeleteCollectionModal,
        })
    }

    const toggleAddObjectModal = (): void => {
        dispatch({
            type: 'TOGGLE_ADD_OBJECT_MODAL',
            payload: !state.modals.displayAddObjectModal,
        })
    }

    const toggleDeleteObjectModal = (id: number): void => {
        dispatch({
            type: 'TOGGLE_DELETE_OBJ_MODAL',
            payload: id
        })
    }

    const toggleEditCollectionNameModal = (value: boolean): void => {
        dispatch({
            type: 'TOGGLE_EDIT_COLLECTION_NAME_MODAL',
            payload: value
        })
    }

    return (
        <CollectionContext.Provider value={{
            ...state,
            modals: {
                ...state.modals,
                toggleDeleteCollectionModal,
                toggleAddObjectModal,
                toggleDeleteObjectModal,
                toggleEditCollectionNameModal,
            },
            setArtifacts,
            setCurrentPage,
            setLoading,
            setCollectionId,
            setSortBy,
        }}>
            {children}
        </CollectionContext.Provider>
    )
}
