import {ISharedWithMeState} from "./shared-with-me.interfaces";
import React, {createContext, useReducer} from "react";
import SharedWithMeReducer from "./shared-with-me.reducer";

const initialState: ISharedWithMeState = {
    isLoadingObjects: true,
    isLoadingCollections: true,
    artifacts: [],
    collections: [],
    statistics: {
        totalObjects: 0,
        totalCollections: 0,
    },
    paginationObjects: {
        page: 0,
        size: 4,
        totalCount: 0,
        numberOfPages: 0,
    },
    paginationCollections: {
        page: 0,
        size: 4,
        totalCount: 0,
        numberOfPages: 0,
    },
    setArtifacts: () => null,
    setCollections: () => null,
    setCurrentPageObjects: () => null,
    setCurrentPageCollections: () => null,
    setLoadingObjects: () => null,
    setLoadingCollections: () => null,
}

export const SharedWithMeContext = createContext(initialState);

export const SharedWithMeProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(SharedWithMeReducer, initialState);

    const setArtifacts = (artifacts: any): void => {
        dispatch({
            type: 'SET_ARTIFACTS',
            payload: artifacts
        })
    }

    const setCollections = (collections: any): void => {
        dispatch({
            type: 'SET_COLLECTIONS',
            payload: collections
        })
    }

    const setCurrentPageCollections = (page: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE_COLLECTIONS',
            payload: page,
        })
    }

    const setCurrentPageObjects = (page: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE_OBJECTS',
            payload: page,
        })
    }

    const setLoadingObjects = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING_OBJECTS',
            payload: loading
        });
    }

    const setLoadingCollections = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING_COLLECTIONS',
            payload: loading
        });
    }

    return (
        <SharedWithMeContext.Provider value={{
            ...state,
            setArtifacts,
            setCollections,
            setCurrentPageObjects,
            setCurrentPageCollections,
            setLoadingObjects,
            setLoadingCollections,
        }}>
            {children}
        </SharedWithMeContext.Provider>
    )
}
