import React, { createContext, useReducer } from 'react';
import ObjectDetailsReducer from './object-details.reducer';
import { ICategory, IDocument, IObjectDetailsState, IPhoto } from './object-details.interfaces';

const initialState: IObjectDetailsState = {
    artifact: {
        id: 0,
        title: '',
        description: '',
        exactValue: null,
        minimumValue: null,
        maximumValue: null,
        width: null,
        height: null,
        depth: null,
        weight: null,
        exactProductionYear: null,
        lowerProductionYear: null,
        upperProductionYear: null,
        detailsPercent: 0,
        isFeedbackImported: false,
        isSharedToPublicFeed: false,
        paymentFreePass: false,
        origin: '',
        material: '',
        style: '',
        artist: '',
        artistBio: '',
        condition: '',
        createdDate: '',
        documents: [],
        feedbackRequests: [],
        experts: [],
        photos: [],
        collections: [],
        categories: [],
        materials: [],
        conditions: [],
        eras: [],
        origins: [],
        styles: [],
        feedbacks: [],
        artistImageUrl: null,
    },
    loader: {
        delete: false,
        setDeleteLoader: () => null,
    },
    modals: {
        displayConfirmDelete: false,
        toggleConfirmDeleteModal: () => null,
        displayUnsavedChangesModal: false,
        toggleUnsavedChangesModal: () => null,
        displayShareObjectOptionsModal: false,
        toggleShareObjectOptionsModal: () => null,
        displayShareObjectToPersonsModal: false,
        toggleShareObjectToPersonsModal: () => null,
        displayShareObjectToFeedModal: false,
        toggleShareObjectToFeedModal: () => null,
        displayMissingInfoModal: false,
        toggleMissingInfoModal: () => null,
        displayRequestAssessmentModal: false,
        toggleRequestAssessmentModal: () => null,
        displayRecommendedExpertsModal: false,
        toggleRecommendedExpertsModal: () => null,
        displayUnsavedPhotosModal: false,
        toggleDisplayUnsavedPhotosModal: () => null,
    },
    onEditMode: false,
    hasUnsavedPhotos: false,
    setArtifact: () => null,
    setTitleAndDescription: () => null,
    setCategories: () => null,
    setEditMode: () => null,
    addNewPhotos: () => null,
    addNewDocuments: () => null,
    setPhotos: () => null,
};

export const ObjectDetailsContext = createContext(initialState);

export const ObjectDetailsProvider: any = ({ children }: any) => {
    const [state, dispatch] = useReducer(ObjectDetailsReducer, initialState);

    const setArtifact = (artifact: unknown, justObject: boolean): void => {
        dispatch({
            type: 'SET_ARTIFACT',
            payload: { artifact, justObject },
        });
    };

    const setTitleAndDescription = (title: string, description: string): void => {
        dispatch({
            type: 'SET_TITLE_AND_DESCRIPTION',
            payload: { title, description },
        });
    };

    const setCategories = (categories: ICategory[]): void => {
        dispatch({
            type: 'SET_CATEGORIES',
            payload: categories,
        });
    };

    const toggleConfirmDeleteModal = (): void => {
        dispatch({
            type: 'TOGGLE_CONF_DELETE_MODAL',
            payload: !state.modals.displayConfirmDelete,
        });
    };

    const toggleUnsavedChangesModal = (): void => {
        dispatch({
            type: 'TOGGLE_UNSAVED_CHANGES_MODAL',
            payload: !state.modals.displayUnsavedChangesModal,
        });
    };

    const toggleShareObjectOptionsModal = (): void => {
        dispatch({
            type: 'TOGGLE_SHARE_OBJECT_OPTIONS_MODAL',
            payload: !state.modals.displayShareObjectOptionsModal,
        });
    };

    const toggleShareObjectToPersonsModal = (): void => {
        dispatch({
            type: 'TOGGLE_SHARE_OBJECT_PERSONS_MODAL',
            payload: !state.modals.displayShareObjectToPersonsModal,
        });
    };

    const toggleShareObjectToFeedModal = (): void => {
        dispatch({
            type: 'TOGGLE_SHARE_OBJECT_FEED_MODAL',
            payload: !state.modals.displayShareObjectToFeedModal,
        });
    };

    const toggleMissingInfoModal = (): void => {
        dispatch({
            type: 'TOGGLE_MISSING_INFO_MODAL',
            payload: !state.modals.displayMissingInfoModal,
        });
    };

    const toggleRequestAssessmentModal = (): void => {
        dispatch({
            type: 'TOGGLE_REQUEST_ASSESSMENT_MODAL',
            payload: !state.modals.displayRequestAssessmentModal,
        });
    };

    const toggleRecommendedExpertsModal = (): void => {
        dispatch({
            type: 'TOGGLE_RECOMMENDED_EXPERTS_MODAL',
            payload: !state.modals.displayRecommendedExpertsModal,
        });
    };

    const setDeleteLoader = (value: boolean): void => {
        dispatch({
            type: 'SET_DELETE_LOADER',
            payload: value,
        });
    };

    const setEditMode = (value: boolean): void => {
        dispatch({
            type: 'SET_EDIT_MODE',
            payload: value,
        });
    };

    const addNewPhotos = (photos: IPhoto): void => {
        dispatch({
            type: 'ADD_NEW_PHOTOS',
            payload: photos,
        });
    };

    const addNewDocuments = (documents: IDocument): void => {
        dispatch({
            type: 'ADD_NEW_DOCUMENTS',
            payload: documents,
        });
    };

    const toggleDisplayUnsavedPhotosModal = (value: boolean): void => {
        dispatch({
            type: 'TOGGLE_UNSAVED_PHOTOS_MODAL',
            payload: value,
        });
    };

    const setPhotos = (value: IPhoto[]): void => {
        dispatch({
            type: 'SET_PHOTOS',
            payload: value,
        });
    };

    return (
        <ObjectDetailsContext.Provider
            value={{
                ...state,
                modals: {
                    ...state.modals,
                    toggleConfirmDeleteModal,
                    toggleUnsavedChangesModal,
                    toggleShareObjectOptionsModal,
                    toggleShareObjectToPersonsModal,
                    toggleShareObjectToFeedModal,
                    toggleMissingInfoModal,
                    toggleRequestAssessmentModal,
                    toggleRecommendedExpertsModal,
                    toggleDisplayUnsavedPhotosModal,
                },
                loader: {
                    ...state.loader,
                    setDeleteLoader,
                },
                setArtifact,
                setTitleAndDescription,
                setCategories,
                setEditMode,
                addNewPhotos,
                addNewDocuments,
                setPhotos,
            }}
        >
            {children}
        </ObjectDetailsContext.Provider>
    );
};
