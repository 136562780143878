import React from "react";

import {AppRoutes} from "core/helpers/app-routes";
import {UserRoles} from "core/enums";
import {INavLink} from "./nav-link.interfaces";

import RateReviewIcon from "@material-ui/icons/RateReview";
import GroupIcon from "@material-ui/icons/Group";
import LabelIcon from "@material-ui/icons/Label";
import EqualizerIcon from "@material-ui/icons/Equalizer";


const adminLinks: INavLink[] = [
    {
        id: 0,
        name: 'Feedback Request',
        path: AppRoutes.FEEDBACK_REQUESTS,
        icon: <RateReviewIcon />
    },
    {
        id: 1,
        name: 'Collectors',
        path: AppRoutes.COLLECTOR_LISTING,
        icon: <GroupIcon />
    },
    {
        id: 2,
        name: 'Experts',
        path: AppRoutes.EXPERT_LISTING,
        icon: <GroupIcon />
    },
    {
        id: 3,
        name: 'Archivists',
        path: AppRoutes.ARCHIVIST_LISTING,
        icon: <GroupIcon />
    },
    {
        id: 4,
        name: 'Curators',
        path: AppRoutes.CURATOR_LISTING,
        icon: <GroupIcon />
    },
    {
        id: 5,
        name: 'Taxonomy',
        path: AppRoutes.TAXONOMY,
        icon: <LabelIcon />,
    },
    {
        id: 6,
        name: 'Statistics',
        path: AppRoutes.ADMIN_STATISTICS,
        icon: <EqualizerIcon />
    },
];

const expertLinks: INavLink[] = [
    {
        id: 0,
        name: 'Feedback Request',
        path: AppRoutes.FEEDBACK_REQUESTS,
        icon: <RateReviewIcon />
    },
];

export function getNavLinks(userRole: string): INavLink[] {
    if(userRole === UserRoles.ADMIN) {
        return adminLinks;
    }
    if(userRole === UserRoles.EXPERT) {
        return expertLinks;
    }
    return [];
}
