import React, { createContext, useReducer } from 'react';
import CuratorProfileReducer from "./curator-profile.reducer";
import {ICuratorProfileState} from "./curator-profile.interfaces";

const initialState: ICuratorProfileState = {
    data: {
        userId: 0,
        isActive: false,
        type: '',
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        organization: '',
        website: '',
        address: '',
        title: '',
        description: '',
        totalJobs: 0,
        totalActiveJobs: 0,
        reference: '',
        currency: {
            id: -1,
            code: '',
            name: '',
            namePlural: '',
            symbol: '',
            symbolNative: '',
        },
        country: {id: -1, name: ''},
        state: {id: -1, name: ''},
        city: {id: -1, name: ''},
        photoUrl: '',
    },
    setData: () => null,
}

export const CuratorProfileContext = createContext(initialState);

export const CuratorProfileProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(CuratorProfileReducer, initialState);

    const setData = (data: unknown): void => {
        dispatch({
            type: 'SET_DATA',
            payload: data
        })
    }

    return (
        <CuratorProfileContext.Provider value={{
            ...state,
            setData,
        }}>
            {children}
        </CuratorProfileContext.Provider>
    )
}
